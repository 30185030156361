$header-background-color: #FFF;
$primary-color:#db330e;
$secondary-color:#000000;
$extra-color:#f3f3f3;

/* header and navbar */
header{
  background-color: $header_background_color;
}

.navbar{
  margin-bottom:0;
  min-height:65px;
}
.navbar-brand {
  padding: 15px 15px 15px 0;
}
.navbar-brand h2 {
  font-size:24px;
  margin-top:5px;
}

.navbar-nav>li>a {
  padding-top:20px;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:blue;
  margin-right:0;
}
.navbar-toggle .icon-bar {
  background-color: #f9f9f9;
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px 'Lato', sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$primary-color;
}
a:hover, a:active{
  color:$secondary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $primary-color;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
main {
  padding:30px;
}
/* blog posts */
.post {
  padding-bottom : 10px;
  margin-bottom  : 20px;
  border-bottom  : 1px solid #EEEEEE;
}
.post-excerpt {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.meta {
  padding-bottom:10px;
  margin-bottom :10px;
  border-bottom:1px solid #EEEEEE;
  font-size:13px;
}
.meta i {
  color:#999;
}
.author, .date {
  padding:0 10px 0 5px;
}
p.title {
  font-size: 30px;
  line-height: 37px;
  margin-bottom :10px;
}
.post-excerpt a.button{
  font-weight: bold;
}
.post-excerpt a.button, .pagination a {
  text-transform: uppercase;
  color : $primary-color;
  border-bottom: 1px solid $primary-color;
  letter-spacing:.1em;
}
.pagination {
  display:block;
  min-height :50px;
  border-bottom: 1px solid #eee;
}
.pagination a.right {
  display:inline-block;
  float:right;
}
.pagination a.left {
  display:inline-block;
  float:left;
}
.pagination a:hover{
  text-decoration: none;
  font-weight:bold;
}
/* sidebar */
aside h3.widget-title, aside h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  margin-bottom:20px;
  font-weight: normal;
  margin-top:0;
  color:#333;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
}
/* footer */
footer{
  background-color:$secondary-color;
  padding: 30px 0;
  color:#FFF;
  font-size: 14px;
  line-height: 21px;
}
footer .container{
  margin-left: auto;
  max-width: 85%;
}
footer a{
  color:#FFF;
  padding:0 10px;
  border-right: 1px solid #fff;
}
footer a:hover{
  font-weight:bold;
  color:#f1f1f1;
}
footer .copyright{
  padding-left:10px;
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.back-to-top a {
  display:block;
  background-color: $primary-color;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color:$extra-color !important;
  padding               : 30px 0;
  text-align            : center;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;
}
section.page-title-wrap h1.page-title {
  font-size: 30px;
  line-height: 37px;
  color: $primary-color;
  font-weight: 700;
  margin:0 auto;
}

/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  background-color:$secondary-color;
  padding: 20px 0 40px;
}
section#home-search h3{
  color:#FFF;
  padding-top: 40px;
  font-size: 2.5em;
  font-weight: 700;
}
section#home-search form{
  max-width: 50%;
  text-align: center;
  margin: auto;
  padding: 40px;
}
@media screen and (max-width:767px) {
  section#home-search form {
    max-width : 90%;
  }
}
/*home-intro is only only needed when included the signup-message partial */
section#home-intro{
  text-align:center;
  background-color: $extra-color;
  padding: 40px 0 60px;
}
section#home-intro h3{
  margin-bottom: 25px;
  padding:0 10px;
  font-weight: 700;
  color: $primary-color;
}
section#home-intro a.call-to-action {
  background-color:$primary-color;
  border: 1px solid $primary-color;
  color:#FFF;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 3px;
  display:inline-block;
}
/*by-sector, sectors only needed when including sector-module partial
by-location, locations only needed when including city-module partial*/

.by-sector h3, .by-location h3, h3.title { /* h3.Title only needed if joblistings partial is included on the home page */
  margin: 20px 0 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  font-weight: 700;
}
ul.sectors, ul.locations{
  display:flex;
  flex-flow:wrap;
  flex-direction:row;
  justify-content:flex-start;
}
ul.sectors li, ul.locations li {
  padding: 5px 10px 5px 0;
  flex-basis: 48%;
  a i {
    color: $secondary-color;
    width: 25px;
    padding-right: 10px;
  }
}
@media screen and (max-width:510px) {
  ul.sectors li, ul.locations li {
    flex-basis:100%;
  }
}
/*by-sector, sectors only needed when including sector-module partial in sidebar
by-location, locations only needed when including city-module partial in sidebar*/
aside .by-sector h3, aside .by-location h3 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 15px;
  font-weight: normal;
  margin-top: 0;
  color: #333;
  letter-spacing: 2px;
  text-transform: uppercase;
}
aside ul.sectors li, aside ul.locations li {
  flex-basis: 100%;
}
